import React from "react";
import styled from "styled-components";
import bar from "../assets/bar.png";
import barOverlay from "../assets/barOverlay.png";

type ProgressBarProps = {
    className?: string;
    alliance: number;
    horde: number;
};

const ProgressBarWrapper = styled.div`
  position: relative;
  display: flex;
  top: 51.5%;
  left: 6.5%;
  width: 87.4%;
  height: 16.4%;
  background: #440f0f;
  overflow: hidden;
`;

const ProgressBarOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("${barOverlay}");
  background-size: 100% 100%;
`;

const Divider = styled.div`
  display: flex;
  flex: 0;
  &:before {
    position: relative;
    content: "";
    display: flex;
    width: 0;
    height: 100%;
    box-shadow: 0 0 8px 5px rgba(255,255,255,1);
  }
`;

const ProgressBarAlliance = styled.div<{ count: string; }>`
  display: flex;
  flex: ${props => props.count};
  background: linear-gradient(
          90deg,
          ${({ theme }) => theme.fractions.alliance.colors.primary.darken } 0%,
          ${({ theme }) => theme.fractions.alliance.colors.primary.lighten } 100%
  );
`;

const ProgressBarHorde = styled.div<{ count: string; }>`
  display: flex;
  flex: ${props => props.count};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.fractions.horde.colors.primary.lighten } 0%,
    ${({ theme }) => theme.fractions.horde.colors.primary.darken } 100%
  );
`;

const ProgressBar = ({ className, alliance, horde }: ProgressBarProps) => (
    <div className={className}>
        <ProgressBarWrapper>
            <ProgressBarAlliance count={alliance.toString()} />
            <Divider />
            <ProgressBarHorde count={horde.toString()} />
            <ProgressBarOverlay />
        </ProgressBarWrapper>
    </div>
);


export default styled(ProgressBar)`
  position: absolute;
  left: 18%;
  right: 18%;
  aspect-ratio: 622/111;
  bottom: 6%;
  background-size: 100% 100%;
  background-image: url("${bar}");
`;