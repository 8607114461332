import React from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';

type Option = {
    value: string;
    label: string;
};

type SelectWithSearchProps = {
    options: Array<Option>;
    onChange: (newOption: Option) => void;
}

const SelectWithSearch = ({ options, onChange }: SelectWithSearchProps) => (
    <Select
        options={options as any}
        isSearchable
        onChange={onChange as any}
    />
);

export default SelectWithSearch;
