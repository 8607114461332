import React, {useState} from "react";
import styled from 'styled-components';
import board from "./assets/board.png";
import Quest, {isQuestActive, QuestItem} from "./widgets/Quest";
import {TopPlayers, TopPlayersItem} from "./widgets/TopPlayers";
import ProgressBar from "./widgets/ProgressBar";
import src from './assets/logo.png';
import "./Board.scss";
import {useFetch} from "../../../utils/hooks/useFetch";
import useInterval from "../../../utils/hooks/useInterval";
import Buff from "./widgets/Buff";
import Overlay from "./widgets/LogoOverlay";

type LayoutProps = {
    className?: string;
}

const Logo = styled.div`
  position: absolute;
  left: 32%;
  right: 32%;
  aspect-ratio: 534/263;
  top: 14%;
  background-size: 100% 100%;
  background-image: url("${src}");
  padding: 5% 3.3% 5% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-align: center;
`;

const Layout = ({ className }: LayoutProps) => {
    const [quest, setQuest] = useState<string>("");
    const { data: topUsers, reFetch: reFetchTop } = useFetch<any>('api/users/top');
    const { data: totalCount, reFetch: reFEtchTotal } = useFetch<any>('api/users/total');
    const { data: buffs, reFetch: reFetchBuffs } = useFetch<any>('api/buffs/active');

    const topPlayers = topUsers
        ?.map((item: any) => ({ ...item, PointsTotal: item?.PointsTotal ? parseInt(item.PointsTotal) : 0 }))
        ?.sort((a: any, b: any) => {
            if (a.PointsTotal && b.PointsTotal) {
                return b.PointsTotal - a.PointsTotal;
            } else if (a.PointsTotal) {
                return -1;
            } else {
                return 1;
            }
        }).reduce((acc: any, item: any) => {
            acc[item.FractionCode].push(item);
            return acc;
        }, { H: [], A: [] });

    const hordeTotal = totalCount?.SumPointsHorde ? parseInt(totalCount.SumPointsHorde) : 0;
    const allianceTotal = totalCount?.SumPointsAlliance ? parseInt(totalCount.SumPointsAlliance) : 0;

    useInterval(() => {
        if(quest !== "Elemental circle" && isQuestActive('15:00', '16:00')) {
            setQuest("Elemental circle");
        } else if(quest !== "Arcane sanctuary" && isQuestActive('16:00', '19:00')) {
            setQuest("Arcane sanctuary");
        } else if(quest !== "Temple of fortitude" && isQuestActive('19:00', '20:00')) {
            setQuest("Temple of fortitude");
        } else if(quest !== "The Arena" && isQuestActive('20:00', '21:00')) {
            setQuest("The Arena");
        } else if(quest !== "The Wilderness" && isQuestActive('21:00', '23:59')) {
            setQuest("The Wilderness");
        }
        reFetchTop();
        reFEtchTotal();
        reFetchBuffs();
    }, 2000);

    return (
        <div className={className}>
            <Overlay />
            <TopPlayers fraction="alliance">
                {topPlayers?.A.map((item: any, index: number) => (
                    <TopPlayersItem key={index} name={item.Nickname} count={item.PointsTotal} />
                ))}
                <br />
                <TopPlayersItem name="Total" count={allianceTotal} />
            </TopPlayers>
            <TopPlayers fraction="horde">
                {topPlayers?.H.map((item: any, index: number) => (
                    <TopPlayersItem key={index} name={item.Nickname} count={item.PointsTotal} />
                ))}
                <br />
                <TopPlayersItem name="Total" count={hordeTotal} />
            </TopPlayers>
            <Logo />
            <Quest>
                <QuestItem>
                    {quest}
                </QuestItem>
            </Quest>
            <Buff active={buffs?.A ?? []} fraction="alliance" />
            <ProgressBar alliance={allianceTotal} horde={hordeTotal} />
            <Buff active={buffs?.H ?? []} fraction="horde" />
        </div>
    )
};

export default styled(Layout)`
  font-family: DefaultFont, sans-serif;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("${board}");
  background-size: 100% 100%;

`;