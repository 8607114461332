import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BrandingThemeProvider from "./providers/BrandingThemeProvider";

import Board from './components/wow/Board/Layout';
import InnBar from './components/wow/Bar/Bar';
import OpenBar from './components/wow/Bar/OpenBar';
import Users from './components/wow/Users/Layout';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <BrandingThemeProvider>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Board />} />
                <Route path="/bar/inn" element={<InnBar />} />
                <Route path="/bar/open" element={<OpenBar />} />
                <Route path="/users" element={<Users />} />
            </Routes>
        </BrowserRouter>
    </BrandingThemeProvider>
);
