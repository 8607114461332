import React, {useState} from "react";
import useInterval from "../../../../utils/hooks/useInterval";
import styled from "styled-components";

import l2p from '../assets/logos/l2p.jpg';
import bubaku from '../assets/logos/bubaku.jpg';
import animerch from '../assets/logos/animerch.jpg';
import fadee from '../assets/logos/fadee.jpg';
import monster_print from '../assets/logos/monster_print.jpg';

const StyledOverlay = styled.div`
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  img {
    width: 100vw;
    height: 100vh;
  }
`;

const LogoList = [l2p, bubaku, animerch, fadee, monster_print];

const Overlay = () => {
    const [id, setId] = useState(1);
    const [isVisible, setIsVisible] = useState(false);

    const showOverlay = () => {
        setIsVisible(true);
        setTimeout(() => {
            setId(prev => prev + 1);
            setIsVisible(false);
        }, 6000);
    };

    useInterval(() => {
        showOverlay();
    }, 5*60*1000);

    if(!isVisible) {
        return null;
    }

    return (
        <StyledOverlay>
            <img src={LogoList[id % LogoList.length]} alt="logo" />
        </StyledOverlay>
    );
};

export default Overlay;