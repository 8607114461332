import React from "react";
import styled from "styled-components";
import paper from "../assets/paper.png";

type QuestProps = {
    className?: string;
    children?: React.ReactNode;
};

export const isQuestActive = (start: string, end: string) => {
    const now = (new Date()).toLocaleTimeString(
        'cs-CZ',
        { hour12: false, hour: '2-digit', minute: '2-digit' },
    );

    return now >= start && now < end;
}

export const QuestItem = styled.div`
  text-shadow: #211c3b 1px 0 4px;
  color: #d2d2d2;
`;

export default styled.div<QuestProps>`
  position: absolute;
  left: 36%;
  right: 35%;
  aspect-ratio: 373/222;
  top: 50%;
  background-size: 100% 100%;
  background-image: url("${paper}");
  padding: 5% 3.3% 5% 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.25vw;
  line-height: 55px;
  text-align: center;
`;